'use client';

import { usePathnameWithQuery } from '@/hooks/use-pathname-with-query';
import { useUpdatedValue } from '@/hooks/use-updated-value';
import { AnalyticsProps } from '@/types';
import { EMAIL_REGEX, PASSWORD_REGEX } from '@/utils/regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { I18n } from '@lingui/core';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode, useEffect } from 'react';
import { useBoolean } from 'usehooks-ts';
import * as yup from 'yup';
import { SocialSignup } from './social-signup';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Link, { LinkProps } from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { snackbarStore } from '@/stores/snackbar-store';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  FormContainer,
  TextFieldElement,
  useForm,
  UseFormReturn
} from 'react-hook-form-mui';
import { PasswordElement } from '@/components/forms/password-element';
import { SignInCredentialsInput } from '@/stores/auth-store';
import { useAuthStore } from '@/stores/auth-store-provider';

export function signInValidationSchemaResolver(i18n: I18n) {
  const schema: yup.ObjectSchema<SignInCredentialsInput> = yup.object().shape({
    email: yup
      .string()
      .required(i18n.t('validations.required'))
      .matches(EMAIL_REGEX, i18n.t('validations.invalid-email')),
    password: yup
      .string()
      .required(i18n.t('validations.required'))
      .matches(PASSWORD_REGEX, i18n.t('validations.invalid-password'))
  });
  return yupResolver(schema);
}

export type SignInFormProps = Partial<AnalyticsProps> & {
  header?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  preFormFields?: ReactNode;
  postFormFields?: ReactNode;
  submitButtonLabel?: ReactNode;
  ForgotPasswordProps?: LinkProps;
  BoxProps?: BoxProps;

  redirectUrl?: string;
  readOnly?: boolean;
  disableRedirect?: boolean;
  disableSocialSignup?: boolean;
  disableForgotPassword?: boolean;
  disableSignup?: boolean;
  disableSubmitButton?: boolean;

  formId?: string;
  defaultValues?: Partial<SignInCredentialsInput>;

  onBeforeSubmit?: (
    data: SignInCredentialsInput,
    formContext: UseFormReturn<SignInCredentialsInput>
  ) => void | Promise<void>;
  onSubmit?: (
    data: SignInCredentialsInput,
    formContext: UseFormReturn<SignInCredentialsInput>
  ) => void | boolean | Promise<boolean | void>;
  onAfterSubmit?: (
    data: SignInCredentialsInput,
    formContext: UseFormReturn<SignInCredentialsInput>
  ) => void | Promise<void>;
  onError?: (
    data: SignInCredentialsInput,
    error: Error,
    formContext: UseFormReturn<SignInCredentialsInput>
  ) => void | Promise<void>;
  onLoading?: (loading: boolean) => void;
};

const defaultValues: SignInCredentialsInput = {
  email: '',
  password: ''
};

export function SignInForm({
  header,
  body,
  footer,
  preFormFields,
  postFormFields,
  submitButtonLabel,
  ForgotPasswordProps,
  BoxProps,

  redirectUrl,
  disableRedirect,
  readOnly,
  disableSocialSignup,
  disableForgotPassword,
  disableSignup,
  disableSubmitButton,

  formId,
  defaultValues: defaultValuesProp,

  onBeforeSubmit,
  onSubmit,
  onAfterSubmit,
  onError,
  onLoading,

  analyticsElementId = 'sign-in-button',
  analyticsParentId = 'Sign In Page',
  analyticsContext
}: SignInFormProps) {
  const authStore = useAuthStore();
  const { i18n } = useLingui();
  const { pathnameWithQuery: forgotPasswordUrl } = usePathnameWithQuery({
    pathname: '/forgot-password'
  });
  const { pathnameWithQuery: signUpUrl } = usePathnameWithQuery({
    pathname: '/sign-up'
  });
  const {
    value: isLoading,
    setTrue: startLoading,
    setFalse: endLoading
  } = useBoolean();

  const formContext = useForm<SignInCredentialsInput>({
    defaultValues: { ...defaultValues, ...defaultValuesProp },
    resolver: signInValidationSchemaResolver(i18n)
  });

  const onLoadingRef = useUpdatedValue(onLoading);

  useEffect(() => {
    onLoadingRef.current?.(isLoading);
  }, [onLoadingRef, isLoading]);

  const handleSubmit = async (data: SignInCredentialsInput) => {
    let continueLoading = true;
    startLoading();
    try {
      await onBeforeSubmit?.(data, formContext);
      rudderanalytics.track('Click', {
        element_id: analyticsElementId,
        type: 'BUTTON',
        parent_id: analyticsParentId,
        email: data.email,
        context: analyticsContext
      });

      snackbarStore.set.close();

      if (onSubmit) {
        continueLoading = !!(await onSubmit(data, formContext));
      } else {
        await authStore.set.signIn({ ...data, redirectUrl }, disableRedirect);
      }

      await onAfterSubmit?.(data, formContext);
    } catch (error: any) {
      snackbarStore.set.create(
        'error',
        i18n.t('auth-errors.incorrect-username-password-error')
      );
      await onError?.(data, error, formContext);
      continueLoading = false;
    }

    if (!continueLoading) {
      endLoading();
    }
  };

  const defaultHeader = (
    <Stack spacing={0.5} direction="column">
      <Typography fontWeight="500" variant="h6">
        <Trans id="sign-in.h1">Log into your account</Trans>
      </Typography>
      {!disableSignup && (
        <Typography color="text.secondary" variant="body2">
          <Trans id="sign-in.trial-cta">
            New to GrowthDay?{' '}
            <Link
              id="free-trial-button"
              data-testid="free-trial-button"
              href={signUpUrl}
              color="text.secondary"
              underline="always"
              prefetch={false}
              onClick={() => {
                rudderanalytics.track('Click', {
                  element_id: 'free-trial-button',
                  type: 'BUTTON',
                  parent_id: 'sign-in-page'
                });
              }}
            >
              Start your free trial
            </Link>
          </Trans>
        </Typography>
      )}
    </Stack>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={{ xs: 1, sm: 2 }}
      maxWidth={620}
      marginX="auto"
      {...BoxProps}
    >
      {header ?? defaultHeader}
      <FormContainer
        formContext={formContext}
        onSuccess={handleSubmit}
        FormProps={{ id: formId }}
      >
        <Stack spacing={{ xs: 1.5, sm: 2 }} direction="column">
          {!disableSocialSignup && (
            <SocialSignup
              analyticsParentId={analyticsParentId}
              analyticsContext={analyticsContext}
            />
          )}
          {preFormFields}
          <TextFieldElement
            autoFocus={!defaultValuesProp?.email}
            data-testid="email-input"
            name="email"
            id="email-input"
            type="email"
            label={<Trans id="sign-in.email-label">Email address</Trans>}
            placeholder={i18n.t({
              id: 'sign-in.email-placeholder',
              message: 'Enter email address'
            })}
            aria-label={i18n.t({
              id: 'sign-in.email-placeholder',
              message: 'Enter email address'
            })}
            margin="none"
            required
            autoComplete="email"
            onClick={() => {
              rudderanalytics.track('Click', {
                element_id: 'email-input',
                type: 'TEXT',
                parent_id: analyticsParentId,
                context: analyticsContext
              });
            }}
            InputProps={{
              readOnly: readOnly || isLoading
            }}
          />
          <PasswordElement
            autoFocus={!!defaultValuesProp?.email}
            data-testid="password-input"
            name="password"
            id="password-input"
            label={<Trans id="sign-in.password-label">Password</Trans>}
            placeholder={i18n.t({
              id: 'sign-in.password-placeholder',
              message: 'Enter password'
            })}
            aria-label={i18n.t({
              id: 'sign-in.password-placeholder',
              message: 'Enter password'
            })}
            margin="none"
            required
            autoComplete="current-password"
            onClick={() => {
              rudderanalytics.track('Click', {
                element_id: 'password-input',
                type: 'TEXT',
                parent_id: analyticsParentId,
                context: analyticsContext
              });
            }}
            InputProps={{
              readOnly: readOnly || isLoading
            }}
          />
          {!disableForgotPassword && (
            <Link
              variant="body2"
              color="text.secondary"
              underline="always"
              href={forgotPasswordUrl}
              {...ForgotPasswordProps}
              onClick={(event) => {
                rudderanalytics.track('Click', {
                  element_id: 'forgot-password-button',
                  type: 'BUTTON',
                  parent_id: analyticsParentId,
                  context: analyticsContext
                });
                ForgotPasswordProps?.onClick?.(event);
              }}
              sx={[
                { alignSelf: 'flex-start' },
                ...(Array.isArray(ForgotPasswordProps?.sx)
                  ? ForgotPasswordProps?.sx
                  : [ForgotPasswordProps?.sx])
              ]}
            >
              <Trans id="sign-in.forgot-password-link">
                Forgot your password?
              </Trans>
            </Link>
          )}
          {postFormFields}
          {!disableSubmitButton && (
            <LoadingButton
              loading={isLoading}
              id="sign-in-button"
              data-testid="sign-in-button"
              type="submit"
              size="large"
              fullWidth
              aria-label={i18n.t('sign-up.sign-in-button')}
            >
              {submitButtonLabel || (
                <Trans id="sign-up.sign-in-button">Log In</Trans>
              )}
            </LoadingButton>
          )}
        </Stack>
      </FormContainer>
      {body}
      {footer}
    </Box>
  );
}
